import React from 'react'
import classNames from 'classnames'

type TableBodyProps = {
  className?: string
  children?: React.ReactNode
}

export const TableBody = ({ className = '', children }: TableBodyProps) => (
  <tbody
    className={classNames({
      [className]: className
    })}
  >
    {children}
  </tbody>
)
