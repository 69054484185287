import * as React from "react";
import {IconProps, getIconSize} from "./IconInterface"

export const IconChevronDoubleRight= ({
  className,
  size = "md"
}: IconProps) => {
  const sizeOption = getIconSize(size)
  return (
    <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    className={`${className} ${sizeOption}`}
  >
    <path
      d="m12 5 7 7-7 7M4 5l7 7-7 7"
      stroke="#1C1F2B"
      strokeWidth={2}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
  )
}
