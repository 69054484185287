import classNames from 'classnames'
import React from 'react'

import { Bouncer as BouncerLoader } from '../Bouncer'

interface ButtonProps {
  label?: string
  type?: 'button' | 'submit' | 'reset' | undefined
  onClick?: (e?: React.MouseEvent<HTMLElement>) => void
  disabled?: boolean
  className?: string
  size?: string
  color?: string
  icon?: React.ReactNode
  iconLeft?: React.ReactNode
  iconRight?: React.ReactNode
  isLoading?: boolean
  id?: string
}

const getButtonSize = (size: string | undefined) => {
  switch (size) {
    case 'sm':
      return 'py-1 px-2 h-8'
    case 'md':
      return 'py-2 px-4 h-10'
    default:
      return 'py-3 px-6 h-12'
  }
}
const getFontSize = (size: string | undefined) => {
  switch (size) {
    case 'sm':
      return 'text-xs'
    case 'md':
      return 'text-sm'
    default:
      return 'text-base'
  }
}

const getColor = (color: string | undefined) => {
  switch (color) {
    case 'default':
      return 'bg-white hover:bg-gray-800 text-gray-900 hover:text-white border-gray-900 ring-gray-900'
    case 'default-on-dark':
      return 'bg-blue hover:bg-white text-white hover:text-gray-900 border-white ring-white ring-offset-blue'
    case 'primary':
      return 'bg-primary hover:bg-white focus:bg-primary text-white focus:text-white hover:text-primary border-primary ring-primary'
    case 'primary-on-dark':
      return 'bg-primary_on_dark hover:bg-blue focus:bg-primary_on_dark text-gray-900 hover:text-primary_on_dark focus:text-gray-900 border-primary_on_dark ring-primary_on_dark ring-offset-blue'
    case 'info':
      return 'bg-none hover:bg-info text-info hover:text-white border-info ring-info'
    case 'warning':
      return 'bg-none hover:bg-warning text-warning hover:text-white border-warning ring-warning'
    case 'danger':
      return 'bg-none hover:bg-danger text-danger hover:text-white border-danger ring-danger'
    case 'success':
      return 'bg-none hover:bg-success text-success hover:text-white border-success ring-success'
    default:
      return 'bg-white hover:bg-gray-800 text-gray-900 hover:text-white border-gray-900 focus:border-gray-900 ring-gray-900'
  }
}

export const Button = ({
  className,
  color = 'default',
  disabled = false,
  onClick,
  label,
  size = 'md',
  type,
  icon,
  iconLeft,
  iconRight,
  isLoading,
  id
}: ButtonProps) => {
  const sizeOption = getButtonSize(size)
  const fontClass = getFontSize(size)
  const colorOption = getColor(color)
  const loadingOptions = isLoading && 'cursor-wait hover:none'

  return (
    <button
      className={classNames({
        [colorOption]: true,
        [sizeOption]: true,
        'font-bold text-center rounded align-middle focus:outline-none border focus:ring-offset-2 focus:ring-2 overflow-hidden':
          true,
        [loadingOptions || '']: true,
        'cursor-pointer': !disabled,
        'cursor-not-allowed  bg-opacity-50 border-opacity-50 text-opacity-50 hover:bg-opacity-50 hover:text-opacity-50 hover:border-opacity-50':
          disabled,
        [className || '']: true
      })}
      type={type}
      onClick={onClick}
      disabled={disabled || isLoading}
      id={id}
    >
      {isLoading ? (
        <BouncerLoader color={color} />
      ) : (
        <div className='-mt-0.5'>
          {iconLeft ? (
            <div className='inline-block mr-2 align-middle'>{iconLeft}</div>
          ) : (
            ''
          )}
          {label && !icon ? (
            <div className={`uppercase inline ${fontClass}`}>{label}</div>
          ) : (
            ''
          )}
          {icon ? <div className='inline'>{icon}</div> : ''}
          {iconRight ? (
            <div className='inline-block ml-2 align-middle'>{iconRight}</div>
          ) : (
            ''
          )}
        </div>
      )}
    </button>
  )
}
