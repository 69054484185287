import React from 'react'
import classNames from 'classnames'

type TableRowProps = {
  className?: string
  children?: React.ReactNode
}

export const TableRow = ({ className = '', children }: TableRowProps) => (
  <tr
    className={classNames({
      'border-b border-grey-lighter': true,
      [className]: className
    })}
  >
    {children}
  </tr>
)
