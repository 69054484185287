import React from 'react'

interface LinkProps {
  color?: string
  href?: string
  target?: string
  download?: boolean
  size?: string
  className?: string
  children?: React.ReactNode
}

const getColor = (color: string | undefined) => {
  switch (color) {
    case 'default':
      return 'hover:text-gray-600'
    case 'primary':
      return 'text-primary hover:text-primary-dark'
    case 'hint':
      return 'text-gray-400 hover:text-gray-500'
    default:
      return 'hover:text-gray-600'
  }
}

const getTextSize = (size: string | undefined) => {
  switch (size) {
    case 'sm':
      return 'text-sm'
    case 'md':
      return 'text-base'
    case 'lg':
      return 'text-lg'
    default:
      return 'text-base'
  }
}

export const Link = ({
  color = 'default',
  href,
  target = '_self',
  download = false,
  size = 'default',
  className,
  children
}: LinkProps) => {
  const colorOption = getColor(color)
  const sizeOption = getTextSize(size)

  return (
    <a
      href={href}
      target={target}
      download={download}
      className={`underline ${colorOption} ${sizeOption} ${className}`}
    >
      {children}
    </a>
  )
}
