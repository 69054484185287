import React from 'react'
import classNames from 'classnames'

type TableCellProps = {
  className?: string
  children?: React.ReactNode
}

export const TableCell = ({ className = '', children }: TableCellProps) => (
  <td
    className={classNames({
      [className]: className,
      'p-4': true
    })}
  >
    {children}
  </td>
)
