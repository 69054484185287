import * as React from "react";
import {IconProps, getIconSize} from "./IconInterface"

export const IconDocumentPencil = ({
  className,
  size = "md"
}: IconProps) => {
  const sizeOption = getIconSize(size)
  return (
    <svg width={24} height={24} className={`${className} ${sizeOption}`}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M7 21h10a2 2 0 0 0 2-2V9.414a1 1 0 0 0-.293-.707l-5.414-5.414A1 1 0 0 0 12.586 3H7a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2z"
        stroke="#1C1F2B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
      <path
        d="M21.95 7.05a1 1 0 0 1 0 1.414l-7.99 7.99-1.872.534.457-1.947 7.99-7.99a1 1 0 0 1 1.415 0z"
        fill="#1C1F2B"
      />
    </g>
  </svg>
  )
}