import React from 'react'

import styles from './Bouncer.module.css'

export enum COLOR_TYPE {
  // eslint-disable-next-line no-unused-vars
  PRIMARY = 'primary',
  // eslint-disable-next-line no-unused-vars
  SECONDARY = 'secondary',
  // eslint-disable-next-line no-unused-vars
  TERTIARY = 'tertiary',
  // eslint-disable-next-line no-unused-vars
  INFO = 'info',
  // eslint-disable-next-line no-unused-vars
  WARNING = 'warning',
  // eslint-disable-next-line no-unused-vars
  DANGER = 'danger',
  // eslint-disable-next-line no-unused-vars
  DEFAULT = 'default',
  // eslint-disable-next-line no-unused-vars
  SUCCESS = 'success'
}
export interface BouncerProps {
  color?:
    | COLOR_TYPE.PRIMARY
    | COLOR_TYPE.SECONDARY
    | COLOR_TYPE.TERTIARY
    | COLOR_TYPE.INFO
    | COLOR_TYPE.WARNING
    | COLOR_TYPE.DANGER
    | COLOR_TYPE.DEFAULT
    | string
}

const getColor = (color: COLOR_TYPE | string | undefined) => {
  const colorType = COLOR_TYPE
  switch (color) {
    case COLOR_TYPE.TERTIARY:
      return 'bg-gray-100 hover:bg-gray-200 text-primary ring-primary'
    case COLOR_TYPE.SECONDARY:
      return 'bg-primary hover:bg-primary-light text-white ring-primary'
    case COLOR_TYPE.PRIMARY:
      return 'bg-secondary hover:bg-secondary-light text-white ring-primary'
    case COLOR_TYPE.INFO:
      return 'bg-tertiary hover:bg-tertiary-light text-primary ring-primary'
    case COLOR_TYPE.DEFAULT:
      return 'bg-info hover:bg-info-light text-primary ring-primary'
    case COLOR_TYPE.WARNING:
      return 'bg-warning hover:bg-warning-light text-primary ring-primary'
    case COLOR_TYPE.DANGER:
      return 'bg-danger hover:bg-danger-light text-primary ring-primary'
    case colorType.SUCCESS:
      return 'bg-success hover:bg-success-light text-primary ring-primary'
    default:
      return 'bg-gray-100 hover:bg-gray-200 text-primary ring-primary'
  }
}

export const Bouncer = ({ color = 'default' }: BouncerProps) => (
  <div
    className='flex justify-center items-center bg-transparent relative'
    aria-label='bouncer'
  >
    <div
      className={`${styles.loader} bg-transparent p-2 rounded-full flex space-x-3`}
    >
      <div
        className={`w-2 h-2 ${getColor(
          color
        )} bg-opacity-85 rounded-full animate-bounce`}
      />
      <div
        className={`w-2 h-2 ${getColor(
          color
        )} bg-opacity-85 rounded-full animate-bounce`}
      />
      <div
        className={`w-2 h-2 ${getColor(
          color
        )} bg-opacity-85 rounded-full animate-bounce`}
      />
    </div>
  </div>
)
