import React from 'react'
import classNames from 'classnames'

type TableHeadProps = {
  className?: string
  children?: React.ReactNode
}

export const TableHead = ({ className = '', children }: TableHeadProps) => (
  <thead
    className={classNames({
      [className]: className,
      'font-bold': true
    })}
  >
    {children}
  </thead>
)
