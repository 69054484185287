import React, { useCallback, useEffect } from 'react'

type ModalProps = {
  className?: string
  children?: React.ReactNode
  open?: boolean
  onClose?: () => void
}

export const Modal = ({
  className = '',
  children,
  open,
  onClose
}: ModalProps) => {
  const handleEscapeClick = useCallback((event: any) => {
    if (event.key === 'Escape') {
      onClose && onClose()
    }
  }, [])

  useEffect(() => {
    if (open) {
      document.addEventListener('keydown', handleEscapeClick)
    } else {
      document.removeEventListener('keydown', handleEscapeClick)
    }
    return () => {
      document.removeEventListener('keydown', handleEscapeClick)
    }
  }, [open, onClose])

  const onModalClick = (e: any) => {
    e.stopPropagation()
  }

  return open ? (
    <div
      className={`fixed z-10 inset-0 overflow-y-auto ${className}`}
      role='dialog'
      aria-modal='true'
      onClick={onClose}
      data-testid='modal'
    >
      <div className='flex justify-center min-h-screen pt-2 px-1 pb-20 text-center items-center sm:p-0 bg-gray-900 bg-opacity-50'>
        <div
          className='inline-block align-bottom bg-white rounded text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-2xl sm:w-full'
          onClick={onModalClick}
        >
          <div className='bg-white px-4 py-4'>
            <div className='sm:flex sm:items-start'>
              <div className='w-full'>{children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div />
  )
}
