export interface IconProps {
  className?: string
  size?: string
  stroke?: string
}

export const getIconSize = (size: string | undefined) => {
  switch (size) {
    case 'xs':
      return 'w-4 h-4' // 16px
    case 'sm':
      return 'w-5 h-5' // 20px
    case 'md':
      return 'w-6 h-6' // 24px
    case 'lg':
      return 'w-7 h-7' // 28px
    default:
      return 'w-6 h-6' // 24px
  }
}