import React from 'react'
import classNames from 'classnames'

type TableProps = {
  className?: string
  children?: React.ReactNode
}

export const Table = ({ className = '', children }: TableProps) => (
  <table
    className={classNames({
      [className]: className,
      'w-full': true
    })}
  >
    {children}
  </table>
)
